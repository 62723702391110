/* eslint-disable import/no-extraneous-dependencies */

/* Libraries */

/* Core */
import 'core/controllers'
import { TeePublic } from 'core/globals'
import RudderstackHelpers from 'core/services/rudderstack_helpers';
import initProductClicks from 'core/services/rudderstack'

/* Shared */
import 'shared/autocomplete'
import mtrlModals from 'shared/modals/mtrl_modals'
import ProductHelper from 'shared/helpers/ProductHelper'
import Stores from 'shared/stores'
import Validators from 'shared/validators'
import initExploreNav from 'shared/navigation/explore_nav'

/* Packs Contents */
import ProductPage from 'packs_contents/product_page'

// Core
TeePublic.RudderstackHelpers = RudderstackHelpers;
TeePublic.initProductClicks = initProductClicks;

// Shared
TeePublic.ProductHelper = ProductHelper;
TeePublic.Stores = Stores;
TeePublic.Validators = Validators;

// Packs Contents
TeePublic.ProductPage = ProductPage;

mtrlModals.mtrlModal();
initExploreNav();
